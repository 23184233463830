<template>
  <el-dialog
    :title="$t('vibrationData.curve')"
    :visible.sync="dialogVisible"
    :before-close="handleDialogClose"
    :append-to-body="true"
    width="1200px"
    top="2vh"
    class="small-padding curve">
    <div>
      <el-popover style="color: #409eff;float:left;cursor: pointer;" trigger="click">
        <div id="qrcode" ref="qrCode"></div>
        <div>{{ $t("vibrationData.scanCheck") }}</div>
        <el-button slot="reference" type="text" @click="qrcode()">{{ $t("vibrationData.moreVib") }}</el-button>
      </el-popover>
    </div>
    <div
      v-loading="curveLoading"
      element-loading-background="rgba(0, 0, 0, 0.4)">
      <div style="height: 520px;width: 100%;border:1px solid #000c17;">
        <div
          style="width: 35%; font-size: 0.8vh; line-height: 2vh;padding-top: 10px; text-align: center;float: right;">
          {{ $t("vibrationData.speedMax") }}:&nbsp;{{curve3s.max}}&nbsp;&nbsp;{{ $t("vibrationData.speedMin") }}:&nbsp;{{curve3s.min}}

        </div>
        <div style="width: 100%; height: 370px;">
          <high-charts-spline
            id="spline3"
            line-count="1"
            :data="curve3s.data"
            :color="curve3s.color"></high-charts-spline>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>

  import HighChartsSpline from "@/views/KanBan/HighChartsSpline";
  import QRCode from "qrcodejs2";  // 引入qrcode

  export default {
    components: {HighChartsSpline},
    data() {
      return {
        dialogVisible: false,
        curveLoading: false,
        vibration: {
          id: 0,
        },
        curve3s: {data: [], max: 0, min: 0, color: ["#E4007F"]},
      }
      ;
    },
    mounted() {
    },
    methods: {
      qrcode() {
        this.$refs.qrCode.innerHTML = "";
        let qrcode = new QRCode("qrcode", {
          width: 150,
          height: 150,
          text: "curve:" + this.vibration.id,
          colorDark: "#000",
          colorLight: "#fff",
        });
      },
      handleDialogClose() {
        this.dialogVisible = false;
      },
      open(id) {
        this.dialogVisible = true;
        this.vibration.id = id;
        if (id > 0) {
          this.getECOD();
        }
      },
      getECOD() {
        this.curveLoading = true;
        this.$api.getData("vibrationDatas/curve/" + this.vibration.id).then(res => {
          if (res.status === 200) {
            this.curve3s.data = [{
              data: [],
            }];
            this.curve3s.max = 0;
            this.curve3s.min = 0;
            if (res.data.length > 0) {
              this.bindVelocityInfo(res.data[0], this.curve3s, 0);
              this.curveLoading = false;
            }
          }
        }).catch(() => {
          this.curveLoading = false;
        });
      },
      bindVelocityInfo(data, curves, index) {
        let length = data.velocity.length;
        let fs = data.fs;
        for (let i = 0; i < length; i++) {
          let x = i / fs;
          let velocity = data.velocity[i];
          if (velocity > curves.max) {
            curves.max = velocity.toFixed(3);
          }
          if (velocity < curves.min) {
            curves.min = velocity.toFixed(3);
          }
          curves.data[index].data.push([x, velocity]);
        }
      },
      bindAccmInfo(data, curves, index) {
        let length = data.accm.length;
        let fs = data.fs;
        for (let i = 0; i < length; i++) {
          let x = i / fs;
          let accm = data.accm[i];
          if (accm > curves.max) {
            curves.max = accm.toFixed(3);
          }
          if (accm < curves.min) {
            curves.min = accm.toFixed(3);
          }
          curves.data[index].data.push([x, accm]);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
#qrcode {
  display: inline-block;
}

#qrcode img {
  width: 150px;
  height: 150px;
  background-color: #fff;
  padding: 6px;
}
</style>

<style>
.curve .el-popover,.el-popper {
  text-align: center;
}
</style>